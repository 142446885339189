body, html, #root {
  background-color: #fff;
}

.swal2-container {
  z-index: 99999;
}

.swal2-styled.swal2-confirm {
  background-color: #31a653;
}